import React, { FC } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme, styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

function HomeAppSuccess() {
  const { breakpoints } = useTheme();
  const matchMobileView = useMediaQuery(breakpoints.down("md"));

  const data = [
    {
      title: "Design",
      description:
        "With our top-notch Australian designers, we offer Figma mockups for Android, iOS, websites, or CMS, ensuring alignment and clarity from the project`s beginning.",
      image: "/images/app_success_technology_desktop.webp",
      topMargin: 0,
    },
    {
      title: "Native",
      description:
        "We build your app in native code for long-lasting reliability, free from dependence on ever-changing third-party systems.",
      image: "/images/app_success_marketing_desktop.webp",
      topMargin: 20,
    },
    {
      title: "Capital",
      description:
        "Leveraging our network with top VC firms and companies in Australia, we can assist in raising funds to bring your dream app to life!",
      image: "/images/app_success_design_desktop.webp",
      topMargin: 40,
    },
  ];

  return (
    <Box
      sx={{
        pt: {
          xs: 0,
          md: 8,
        },
        pb: {
          xs: 6,
          md: 8,
        },
        backgroundColor: "background.paper",
      }}
      style={{ marginTop: 30 }}
    >
      <Container>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: 22, md: 38, lg: 44 },
              lineHeight: 1,
              fontWeight: { md: "bold", lg: "bold" },
            }}
          >
            Your 3 Factors for
          </Typography>
        </Grid>
        <Grid
          mt={3}
          sx={{
            mb: { xs: 8, md: -10 },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: 40, md: 90, lg: 108 },
              lineHeight: 1,
              fontWeight: "bold",
            }}
          >
            App Success
          </Typography>
        </Grid>
        <Grid container mb={4} spacing={4}>
          {data.map(({ title, description, image, topMargin }, index) => (
            <Grid
              key={String(index)}
              item
              xs={12}
              md={4}
              sx={{
                mt: { xs: 0, md: topMargin },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  py: { xs: 4, md: 6 },
                  px: { xs: 5, md: 8 },
                  borderRadius: 6,
                }}
                style={{
                  "box-shadow": "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    component="h2"
                    xs={12}
                    sx={{
                      position: "relative",
                      fontSize: { xs: 22, md: 36 },
                      mb: 2,
                      lineHeight: 1,
                      fontWeight: "bold",
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    xs={12}
                    sx={{
                      fontSize: { xs: 16, md: 18 },
                      color: "text.secondary",
                    }}
                  >
                    {description}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  mt={6}
                  mb={2}
                  sx={{
                    splay: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    justifySelf: "center",
                    direction: "column",
                  }}
                >
                  <img
                    src={image}
                    width={matchMobileView ? "60%" : "100%"}
                    height={"auto"}
                    quality={97}
                    alt="Testimonial img"
                  />
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default HomeAppSuccess;
