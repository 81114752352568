import React from "react";
import {
  Box,
  CardMedia,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

function Logo({ onClick, variant }) {
  const { breakpoints } = useTheme();
  const matchMobileView = useMediaQuery(breakpoints.up("md"));

  return (
    <Box onClick={onClick}>
      <Typography
        variant="h4"
        component="h1"
        sx={{
          display: "flex",
          fontWeight: 700,
          "& span": {
            color: variant === "primary" ? "primary.main" : "unset",
          },
          fontSize: matchMobileView ? 40 : 20,
        }}
      >
        <CardMedia
          component="img"
          sx={{ width: matchMobileView ? "45px" : "37px", pr: 1 }}
          image="/images/everest_app_logo.png"
          alt="Everest Apps"
        />
        <div style={{ marginTop: matchMobileView ? 0 : 5 }}>
          Everest<span>Apps</span>
        </div>
      </Typography>
    </Box>
  );
}

Logo.defaultProps = {
  variant: "primary",
};

export default Logo;
