import React from "react";
import Box from "@mui/material/Box";
import { Link as ScrollLink } from "react-scroll";

function Navigation({ onItemClick }) {
  const navigations = [
    {
      label: "Home",
      path: "#",
    },
    {
      label: "Features",
      path: "features",
    },
    {
      label: "Development",
      path: "development",
    },
    {
      label: "Portfolio",
      path: "portfolio",
    },
    {
      label: "Contact",
      path: "contact",
    },
    {
      label: "Free Consultation",
      path: "contact",
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {navigations.map(({ path: destination, label }) => (
        <Box
          component={ScrollLink}
          onClick={() => onItemClick()}
          key={destination}
          activeClass="current"
          to={destination}
          spy={true}
          smooth={true}
          duration={350}
          sx={{
            position: "relative",
            color: "#FFFFFF",
            cursor: "pointer",
            fontWeight: 700,
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 4,
            fontSize: { xs: "1.2rem", md: "2rem" },
            ...(destination === "/" && {
              color: "primary.main",
            }),

            "& > div": { display: "none" },

            "&.current>div": { display: "block" },

            "&:hover": {
              color: "primary.main",
              "&>div": {
                display: "block",
              },
            },
          }}
        >
          {label}
        </Box>
      ))}
    </Box>
  );
}

export default Navigation;
