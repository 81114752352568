import React, { useState } from "react"
import { Box, Container, IconButton } from "@mui/material"
import { Menu, Close } from '@mui/icons-material'
import Logo from "../logo/logo"
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Navigation from "../navigation/navigation"
import RightNavigation from "../navigation/right-navigation"

function Header() {
  const [visibleMenu, setVisibleMenu] = useState(false)
  const { breakpoints } = useTheme()
  const matchMobileView = useMediaQuery(breakpoints.up('md'))

  return (
    <Box sx={{ backgroundColor: 'background.paper' }}>
      <Container sx={{ py: { xs: 2, md: 3 } }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Logo />
          <Box sx={{ ml: 'auto', alignItems: 'center', display: 'inline-flex' }}>
            {matchMobileView && <RightNavigation />}
            <IconButton onClick={() => setVisibleMenu(!visibleMenu)} sx={{ ml: 4 }}>
              <Menu sx={{ width: 32, height: 32 }} />
            </IconButton>
          </Box>
          {visibleMenu && (<Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexDirection: 'column',
              transition: (theme) => theme.transitions.create(['top']),
              ...({
                py: 6,
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
                zIndex: 'appBar',
                position: 'fixed',
                height: { xs: visibleMenu ? '100%' : '0' },
                top: visibleMenu ? 0 : '-120vh',
                left: 0,
              }),
            }}
          >
            <IconButton onClick={() => setVisibleMenu(!visibleMenu)} sx={{ mb: 4, mt: 2 }}>
              <Close sx={{ color: '#FFFFFF', width: 32, height: 32 }} />
            </IconButton>
            <Navigation onItemClick={() => setVisibleMenu(!visibleMenu)} />
          </Box>)}
        </Box>
      </Container>
    </Box>
  )
}

export default Header