import React, { FC, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  TextField,
} from "@mui/material";
import emailjs from "@emailjs/browser";

function HomeContact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [submit, setSubmit] = useState(false);
  const form = useRef();

  const [openDialog, setOpenDialog] = React.useState(false);

  const [msgDialog, setMsgDialog] = React.useState("");
  const [titleDialog, setTitleDialog] = React.useState("");

  const submitForm = (e) => {
    e.preventDefault();

    console.log("form --> " + form.current);
    setSubmit(true);

    if (name === "" || !isValidEmail(email) || phoneNumber === "") {
      return;
    }

    emailjs
      .sendForm(
        "service_ocs1sre",
        "template_9hfdqgo",
        form.current,
        "xtaAsPF6g9fm5Ah63"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSubmit(false);
          setEmail("");
          setName("");
          setPhoneNumber("");
          setOpenDialog(true);
          setTitleDialog("Email Sent!!");
          setMsgDialog(
            "Thank you for providing your details. Our team at Everest Apps will contact you soon!"
          );
        },
        (error) => {
          console.log(error.text);
          setTitleDialog("Email sending failed.");
          setMsgDialog(error.text);
          setOpenDialog(true);
        }
      );
    return false;
  };

  function isValidEmail(_email) {
    return /\S+@\S+\.\S+/.test(_email);
  }

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Box
      id={"contact"}
      sx={{
        backgroundColor: "background.paper",
        pt: { xs: 4, md: 8 },
        pb: { xs: 8, md: 16 },
        px: { xs: 0, md: 24 },
      }}
    >
      <Container>
        <form
          sx={{
            textAlign: "center",
          }}
          ref={form}
          onSubmit={submitForm}
        >
          <Typography
            variant="h1"
            component="h2"
            sx={{
              textAlign: "center",
              mb: { xs: 4, md: 8 },
              fontSize: { xs: 32, md: 42 },
            }}
          >
            Let's Bring Your Idea To Life!
          </Typography>
          <Box
            sx={{
              backgroundColor: "background.paper",
              borderRadius: 10,
              // boxShadow: 2,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "space-around",
              py: { xs: 4, md: 8 },
              px: { xs: 4, md: 8 },
              mx: "auto",
            }}
            style={{
              "box-shadow": "rgba(0, 0, 0, 0.1) 0px 4px 12px",
            }}
          >
            <TextField
              sx={{
                backgroundColor: "background.paper",
                borderRadius: 3,
                width: { xs: "100%", md: "70%" },
                mb: 3,
              }}
              label="Name*"
              variant="outlined"
              error={submit && name === ""}
              onChange={(e) => setName(e.target.value)}
              name="user_name"
            />
            <TextField
              sx={{
                backgroundColor: "background.paper",
                borderRadius: 3,
                width: { xs: "100%", md: "70%" },
                height: 48,
                mb: 4,
              }}
              label="Email*"
              variant="outlined"
              error={submit && !isValidEmail(email)}
              onChange={(e) => setEmail(e.target.value)}
              name="user_email"
            />
            <TextField
              sx={{
                backgroundColor: "background.paper",
                borderRadius: 3,
                width: { xs: "100%", md: "70%" },
                height: 48,
                mb: 8,
              }}
              label="Phone Number*"
              variant="outlined"
              error={submit && phoneNumber === ""}
              onChange={(e) => setPhoneNumber(e.target.value)}
              name="user_phone"
            />
            <Button
              sx={{
                py: 1.3,
                px: 12,
                background: "linear-gradient(270deg,#002962,#1057A5)!important",
                border: "0",
                borderRadius: 48,
              }}
              variant="contained"
              type="submit"
            >
              Submit
            </Button>

            <Typography
              variant="h1"
              component="h2"
              sx={{ mt: 4, mb: 4, fontSize: { xs: 18, md: 24 } }}
            >
              or email us at{" "}
              <Link href="mailto:hello@everestapps.io">
                hello@everestapps.io
              </Link>
            </Typography>
          </Box>
        </form>
      </Container>

      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{titleDialog}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {msgDialog}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default HomeContact;
