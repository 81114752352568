export const privacyPolicy =
  "At Everest Apps, we are committed to protecting the privacy and confidentiality of our users' personal information. This Privacy Policy outlines how we collect, use, and safeguard the data we collect through our website and services. By accessing or using Everest Apps, you agree to the terms of this Privacy Policy." +
  '<br><br><p style="font-weight: bold;">Information Collection:</p>We may collect personal information such as names, email addresses, and contact details when provided voluntarily by users. We also collect non-personal information, including browser type, IP address, and website usage statistics, through cookies and similar technologies.' +
  '<br><br><p style="font-weight: bold;">Information Use:</p>' +
  "We may use the collected information to personalise user experiences, provide requested services, improve our offerings, and communicate with users about updates, promotions, and relevant information. We do not sell or rent personal information to third parties." +
  '<br><br><p style="font-weight: bold;">Data Security:</p>' +
  "We implement industry-standard security measures to protect user data from unauthorised access, alteration, or disclosure. However, no method of data transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security." +
  '<br><br><p style="font-weight: bold;">Third-Party Links:</p>' +
  "Our website and services may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these external sites. We encourage users to review the privacy policies of those third-party platforms." +
  '<br><br><p style="font-weight: bold;">Children\'s Privacy:</p>' +
  "We do not knowingly collect personal information from individuals under the age of 13. If we become aware of such data being provided to us, we will take steps to delete it." +
  '<br><br><p style="font-weight: bold;">Updates to Privacy Policy:</p>' +
  "We may update this Privacy Policy periodically to reflect changes in our practices. We encourage users to review the policy periodically for any updates." +
  '<br><br><p style="font-weight: bold;">Contact Information:</p>' +
  "For any questions or concerns regarding this Privacy Policy or our privacy practices, please contact us at hello@everestapps.io" +
  "By using Everest Apps, you acknowledge and agree to the collection, use, and storage of your personal information as described in this Privacy Policy." +
  '<br><br><p style="font-weight: bold;">Non-Disclosure Agreement (NDA):</p>' +
  "In certain situations, Everest Apps may engage in discussions or collaborations that require the sharing of confidential or proprietary information. In such cases, we may request users to sign a separate Non-Disclosure Agreement (NDA) to protect the confidentiality of shared information. The NDA will outline the terms and conditions governing the use and disclosure" +
  "of confidential information and will prevail over any conflicting provisions in this Privacy Policy." +
  "The NDA will be a separate agreement and will not modify or supersede the terms of this Privacy Policy, except where explicitly stated. The NDA will be applicable only to the specific confidential information exchanged between the parties and will not affect the handling of other non-confidential data collected as per this Privacy Policy." +
  "Please note that the decision to sign an NDA will be determined on a case-by-case basis and may not be required for all engagements or interactions with Everest Apps." +
  "If you have any questions or require further information regarding NDAs or the protection of confidential information, please contact us at hello@everestapps.io" +
  "By using Everest Apps and engaging in discussions that may involve confidential information, you acknowledge and agree to the separate terms of any Non-Disclosure Agreement that may be required.";

export const terms =
  'Please read these Terms and Conditions ("Terms") carefully before using Everest Apps ("the Service"), operated by Everest Apps Pty Ltd ("the Company"). These Terms govern your use of the Service and constitute a legally binding agreement between you and the Company.' +
  '<br><br><p style="font-weight: bold;">Acceptance of Terms:</p>' +
  "By accessing or using the Service, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you may not use the Service." +
  '<br><br><p style="font-weight: bold;">Intellectual Property Ownership:</p>' +
  "Unless otherwise outlined in a separate agreement between the client and the Company, all intellectual property rights in the deliverables produced by Everest Apps during the provision of services shall belong to the client. This includes, but is not limited to, any custom software, designs, graphics, or content created specifically for the client. The client retains ownership of their intellectual property rights, subject to any licences granted to the Company for the sole purpose of providing the services." +
  '<br><br><p style="font-weight: bold;">User Responsibilities:</p>' +
  "You are solely responsible for your use of the Service and agree to comply with all applicable laws and regulations. You must not use the Service in a manner that infringes upon the rights of others, promotes illegal activities, or violates any third-party agreements." +
  '<br><br><p style="font-weight: bold;">Privacy:</p>' +
  "The collection and use of personal information are governed by our Privacy Policy. By using the Service, you consent to the Company's collection, storage, and processing of your personal information as described in the Privacy Policy." +
  '<br><br><p style="font-weight: bold;">Limitation of Liability:</p>' +
  'The Company shall not be liable for any direct, indirect, incidental, consequential, or exemplary damages arising from your use of the Service. The Service is provided on an "as is" and "as available" basis, without any warranties or representations of any kind.' +
  '<br><br><p style="font-weight: bold;">Third-Party Links:</p>' +
  "The Service may contain links to third-party websites or services that are not owned or controlled by the Company. The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that the Company shall not be responsible or liable for any damage or loss caused by the use of such links." +
  '<br><br><p style="font-weight: bold;">Non-Disclosure Agreement (NDA):</p>' +
  "In certain circumstances, the client may engage in discussions or collaborations with Everest Apps that require the sharing of confidential or proprietary information. In such cases, the parties may be required to sign a separate Non-Disclosure Agreement (NDA) to protect the confidentiality of shared information. The terms and conditions of the NDA will prevail over any conflicting provisions in these Terms with respect to the handling and protection of confidential information." +
  '<br><br><p style="font-weight: bold;">Modification and Termination:</p>' +
  "The Company reserves the right to modify, suspend, or terminate the Service, or your access to it, with or without notice. The Company may also modify these Terms from time to time, and your continued use of the Service after any such modifications shall constitute your consent to the updated Terms." +
  '<br><br><p style="font-weight: bold;">Governing Law:</p>' +
  "These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law provisions. Any disputes arising out of these Terms shall be subject to the exclusive jurisdiction of the courts in NSW, Australia." +
  '<br><br><p style="font-weight: bold;">Entire Agreement:</p>' +
  "These Terms constitute the entire agreement between you and the Company regarding the Service and supersede any prior agreements or understandings." +
  "If you have any questions or concerns regarding these Terms, please contact us at helo@everestapps.io" +
  "By using Everest Apps, you acknowledge and agree to abide by these Terms and Conditions, including the provisions on intellectual property ownership, unless otherwise outlined in a separate agreement or Non-Disclosure Agreement (NDA).";

export const faq =
  '<p style="font-weight: bold;">What services does Everest Apps provide?</p>' +
  "Everest Apps offers a range of services including mobile app development, web development, UI/UX design, software development, and technology consulting. We specialise in creating innovative and user-friendly solutions tailored to meet our clients' specific needs." +
  '<br><br><p style="font-weight: bold;">How experienced is the team at Everest Apps?</p>' +
  "Our team consists of highly skilled professionals with extensive experience in their respective fields. We have a diverse team of developers, designers, and consultants who have successfully delivered projects for startups, educational institutions, government organisations, and international clients." +
  '<br><br><p style="font-weight: bold;">What industries does Everest Apps work with?</p>' +
  "We have experience working across various industries including education, healthcare, e-commerce, finance, and more. Our expertise allows us to understand the unique requirements and challenges of each industry, enabling us to deliver customised solutions that drive business growth and success." +
  '<br><br><p style="font-weight: bold;">Can Everest Apps assist with the entire app development process?</p>' +
  "Yes, we provide end-to-end app development services. From initial concept ideation and design to development, testing, and deployment, our team will guide you through every step of the process. We strive to ensure a seamless and collaborative experience to bring your app idea to life." +
  '<br><br><p style="font-weight: bold;">How does Everest Apps ensure the quality of its deliverables?</p>' +
  "We follow rigorous quality assurance processes throughout the development lifecycle. Our team adheres to industry best practices, conducts thorough testing, and seeks client feedback at every stage to ensure the final product meets the highest standards of quality, functionality, and user experience." +
  '<br><br><p style="font-weight: bold;">Can Everest Apps work within specific budgets and timelines?</p>' +
  "Absolutely! We understand the importance of budget and timeline considerations. Our team is adept at creating tailored solutions that align with your budget and project timeline. We collaborate closely with our clients to set realistic expectations and deliver results within the agreed-upon parameters." +
  '<br><br><p style="font-weight: bold;">How can I get started with Everest Apps?</p>' +
  "To get started, simply reach out to us via our website contact form or contact information provided. We'll be glad to schedule a consultation to discuss your project requirements, provide insights, and explore how Everest Apps can bring your ideas to life." +
  "If you have any other questions or need further clarification, feel free to contact us. Our team is here to assist you throughout your app development journey.";
