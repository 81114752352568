import React, { useRef } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Slider from "react-slick";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import IconArrowBack from "@mui/icons-material/ArrowBack";
import IconArrowForward from "@mui/icons-material/ArrowForward";
import PortfolioItem from "../portfolio/portfolio-item";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderArrow = (props) => {
  const { onClick, type } = props;
  return (
    <IconButton
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        color: "primary.contrastText",
        "&:hover": {
          backgroundColor: "primary.main",
          color: "primary.contrastText",
        },
        bottom: {
          md: "24px !important",
          position: "absolute",
          width: 48,
          height: 48,
          top: "45%",
        },
        left: {
          xs: type === "prev" ? "1px !important" : "unset !important",
          md: type === "prev" ? "32px !important" : "unset !important",
        },
        right: {
          xs: type === "prev" ? "unset !important" : "1px !important",
          md: type === "prev" ? "unset !important" : "32px !important",
        },
        zIndex: 10,
        boxShadow: 1,
      }}
      disableRipple
      color="inherit"
      onClick={onClick}
    >
      {type === "next" ? (
        <IconArrowForward sx={{ fontSize: 24 }} />
      ) : (
        <IconArrowBack sx={{ fontSize: 24 }} />
      )}
    </IconButton>
  );
};

const data = [
  {
    id: 1,
    title: "Bolt for Gold",
    content:
      "Join Bolt for Gold, the exciting walking and running app where global competitors win cash prizes!",
    rating: "4.1",
    appValue: "Health & Fitness",
    logo: "images/portfolio/portfolio-logo-b4g.png",
    image: "images/portfolio/portfolio-b4g.png",
    src: "#",
  },
  {
    id: 2,
    title: "Talent Lockr",
    content:
      "Talent Lockr is the ultimate player recruitment app making the connection between Player’s, Team’s, Coaches and Agents easy and accessible to everyone. Sign up, Create your profile and instantly connect.",
    rating: "4.2",
    appValue: "Health & Fitness",
    logo: "/images/icons/bonzun.png",
    image: "images/portfolio/portfolio-bonzun.png",
    src: "#",
  },
  {
    id: 3,
    title: "Shomii",
    content:
      "An exciting live video app, coming soon to revolutionise your streaming experience. Stay tuned!",
    rating: "4",
    appValue: "Tours & Travel",
    logo: "images/portfolio/portfolio-logo-shomii.png",
    image: "images/portfolio/portfolio-shomii.png",
    src: "#",
  },
  {
    id: 4,
    title: "My Stylee",
    content:
      "Discover a dynamic website where fans access tips & tricks from Australias top athletes. Welcome to our platform!",
    rating: "4.5",
    appValue: "Health & Fitness",
    logo: "images/portfolio/portfolio-logo-mystylee.png",
    image: "images/portfolio/portfolio-mystylee.png",
    src: "#",
  },
];

const StyledSlickContainer = styled("div")(() => ({
  position: "relative",
  "& .slick-list": {
    /* overflow: 'visible' */
  },
}));

function HomePortfolio() {
  const sliderRef = useRef(null);

  const sliderConfig = {
    infinite: true,
    autoplay: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SliderArrow type="prev" />,
    nextArrow: <SliderArrow type="next" />,
  };

  return (
    <Box
      id="portfolio"
      sx={{ backgroundColor: "background.paper", pb: { xs: 4, md: 8 } }}
    >
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={11} md={7}>
              <Typography
                component="h2"
                sx={{
                  position: "relative",
                  fontSize: { xs: 36, md: 46 },
                  mt: { xs: 4, md: 8 },
                  fontWeight: "bold",
                  lineHeight: 1,
                }}
              >
                From Ideas to Impact.
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={11} md={7}>
              <Typography
                component="h2"
                sx={{
                  position: "relative",
                  fontSize: { xs: 18, md: 28 },
                  mt: { xs: 2, md: 4 },
                  lineHeight: { xs: 1.5, md: 2 },
                }}
              >
                At Everest Apps, we empower start-ups, individuals, and
                organisations, collaborating with hundreds of Australian
                schools, government entities, charities, and international
                clients. Let's ascend the summit of technology together.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <StyledSlickContainer>
              <Slider ref={sliderRef} {...sliderConfig}>
                {data.map((item, index) => (
                  <PortfolioItem key={String(index)} item={item} />
                ))}
              </Slider>
            </StyledSlickContainer>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default HomePortfolio;
