import { Box } from "@mui/material"
import Footer from "../footer/footer"
import Header from "../header/header"

function MainLayout({ children }) {
    return (
      <Box component="main">
        <Header />
        {children}
        <Footer />
      </Box>
    )
  }
  
  export default MainLayout