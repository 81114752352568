import { useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import FooterNavigation from "./footer-navigation";
import SocialLinks from "./footer-social-links";

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#000000",
        py: { xs: 2, md: 10 },
        color: "primary.contrastText",
      }}
    >
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12} md={5}>
            <Box sx={{ width: { xs: "100%", md: 360 }, mb: { xs: 3, md: 0 } }}>
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontSize: { xs: 45, md: 90 }, mb: 2 }}
              >
                Everest Apps
              </Typography>
              <Typography variant="subtitle1" sx={{ letterSpacing: 1, mb: 2 }}>
                We are a pioneering software company dedicated to scaling new
                heights in app development. With our unwavering commitment to
                excellence, we bring you cutting-edge solutions that propel your
                business to the pinnacle of success.
              </Typography>
              {/*  <SocialLinks /> */}
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <FooterNavigation />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
