import React, { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { CardMedia, Grid } from "@mui/material";

function HomeSuccessStrategy() {
  const { breakpoints } = useTheme();
  const matchMobileView = useMediaQuery(breakpoints.down("md"));

  return (
    <Box
      id="development"
      sx={{
        pt: {
          xs: 2,
          md: 8,
        },
        pb: {
          xs: 4,
          md: 4,
        },
        backgroundColor: "background.paper",
      }}
    >
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box xs={12} sx={{ lineHeight: 0 }}>
          <CardMedia
            component="img"
            sx={{ width: "100%" }}
            image="/images/success_strategy_banner_1.png"
            alt="Success Strategy"
          />
        </Box>
        <Typography
          xs={12}
          variant="h1"
          sx={{
            mt: { xs: 4, md: 16 },
            mb: { xs: 6, md: 24 },
            px: 2,
            fontSize: { xs: 24, md: 38, lg: 44 },
            lineHeight: 1,
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Unlock success with Everest App's
        </Typography>
        <Box xs={12} sx={{ lineHeight: 0 }}>
          <CardMedia
            component="img"
            sx={{ width: "100%" }}
            image="/images/success_strategy_banner_2.webp"
            alt="Success Strategy"
          />
        </Box>
      </Grid>
    </Box>
  );
}

export default HomeSuccessStrategy;
