import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CardMedia, Grid, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const PortfolioItem = ({ item }) => {
  return (
    <Box
      sx={{
        mx: { xs: 1, md: 8, lg: 16 },
        my: { xs: 4, md: 8 },
        py: { xs: 3, md: 8 },
        pl: { xs: 3, md: 8 },
        pr: { xs: 4, md: 0 },
        borderRadius: 6,
      }}
      style={{
        "box-shadow": "rgba(0, 0, 0, 0.1) 0px 4px 12px",
      }}
    >
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 2 }}>
            <Grid
              item
              sx={{
                mb: { xs: 3, md: 8 },
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  boxShadow: 2,
                  borderRadius: 2,
                  height: "auto",
                  width: 48,
                  overflow: "hidden",
                  p: "1%",
                  "& img": {
                    width: "100%",
                  },
                }}
              >
                <CardMedia component="img" src={item.logo} />
              </Box>
              <Typography
                component="h2"
                variant="h4"
                sx={{ ml: 2, fontSize: { xs: "1.5rem", md: "2.12rem" } }}
              >
                {item.title}
              </Typography>
            </Grid>
            <Typography sx={{ mb: { xs: 3, md: 8 }, color: "text.secondary" }}>
              {item.content}
            </Typography>
          </Box>
          <Grid
            item
            sx={{
              mb: { xs: 3, md: 8 },
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                variant="h4"
                sx={{ fontSize: { xs: "1.4rem", md: "2rem" } }}
              >
                {item.rating} Stars
              </Typography>
              <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
                App rating
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h4"
                sx={{ fontSize: { xs: "1.4rem", md: "2rem" } }}
              >
                {item.appValue}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
                Category
              </Typography>
            </Box>
          </Grid>
          <Box sx={{ alignItems: "center", mb: { xs: 3, md: 0 } }}>
            <Link href="#">
              <Typography
                variant="subtitle1"
                sx={{
                  alignItems: "center",
                  display: "flex",
                  color: "primary.main",
                }}
              >
                View Portfolio <NavigateNextIcon />
              </Typography>
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              height: "auto",
              width: "100%",
              overflow: "hidden",
              "& img": {
                width: "100%",
              },
            }}
          >
            <CardMedia component="img" src={item.image} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default PortfolioItem;
