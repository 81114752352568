import React from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

function RightNavigation() {
  return (
    <Box sx={{ "& button:first-child": { mr: 2 } }}>
      <ScrollLink to={"contact"} smooth={true} offset={-70}>
        <Button
          sx={{
            py: 1.3,
            px: 2,
            background: "linear-gradient(270deg,#002962,#1057A5)!important",
            border: "0",
            borderRadius: 48,
          }}
          variant="contained"
          disableElevation
        >
          Free Consultation
        </Button>
      </ScrollLink>
    </Box>
  );
}

export default RightNavigation;
