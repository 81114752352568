import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import MuiLink from "@mui/material/Link";
import React, { useEffect, useState } from "react";
import FooterSectionTitle from "./footer-section-title";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { faq, privacyPolicy, terms } from "../../utility/constant";

const courseMenu = [
  {
    label: "UI/UX Design",
    path: "#",
  },
  {
    label: "App Design & Development",
    path: "#",
  },
  {
    label: "AI & IoT Solutions",
    path: "#",
  },
  {
    label: "Web Development",
    path: "#",
  },
];

const pageMenu = [
  {
    label: "Home",
    path: "#",
  },
  {
    label: "Features",
    path: "features",
  },
  {
    label: "Development",
    path: "development",
  },
  {
    label: "Portfolio",
    path: "portfolio",
  },
  {
    label: "Contact",
    path: "contact",
  },
];

const companyMenu = [
  { label: "Contact Us", path: "contact" },
  { label: "Privacy & Policy", path: "privacy" },
  { label: "Term & Condition", path: "terms" },
  { label: "FAQ", path: "faq" },
];

function FooterNavigation() {
  const [open, setOpen] = useState(false);
  const [desc, setDesc] = useState("");
  const [title, setTitle] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  ////// menu display start
  function NavigationItem({ label, path }) {
    const scrollToPath = () => {
      scroll.scrollTo(path, {
        smooth: true,
      });
    };

    const displayDialog = (tag) => {
      if (tag == "privacy") {
        setTitle("Privacy Policy for Everest Apps 2023");
        setDesc(privacyPolicy);
      } else if (tag == "terms") {
        setTitle("Terms and Conditions for Everest Apps");
        setDesc(terms);
      } else {
        setTitle("Everest Apps FAQ");
        setDesc(faq);
      }
      setOpen(true);
    };

    return (
      <ScrollLink to={path} smooth={true} offset={-70}>
        <MuiLink
          underline="hover"
          sx={{
            display: "block",
            mb: 1,
            color: "primary.contrastText",
            cursor: "pointer",
          }}
          onClick={() => {
            if (path == "privacy" || path == "terms" || path == "faq") {
              displayDialog(path);
            } else {
              scrollToPath();
            }
          }}
        >
          {label}
        </MuiLink>
      </ScrollLink>
    );
  }
  /// menu display end

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <FooterSectionTitle title="Services" />
        {courseMenu.map(({ label, path }, index) => (
          <NavigationItem
            key={index + path}
            label={label}
            path={/* path */ "#"}
          />
        ))}
      </Grid>
      <Grid item xs={12} md={4}>
        <FooterSectionTitle title="Menu" />
        {pageMenu.map(({ label, path }, index) => (
          <NavigationItem key={index + path} label={label} path={path} />
        ))}
      </Grid>
      <Grid item xs={12} md={4}>
        <FooterSectionTitle title="About" />
        {companyMenu.map(({ label, path }, index) => (
          <NavigationItem key={index + path} label={label} path={path} />
        ))}
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="body1"
              component="div"
              dangerouslySetInnerHTML={{ __html: desc }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default FooterNavigation;
