import React, { FC } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme, styled } from "@mui/material/styles";
import Marquee from "react-fast-marquee";
import { Grid } from "@mui/material";

function HomeGlobalSuccess() {
  const { breakpoints } = useTheme();
  const matchMobileView = useMediaQuery(breakpoints.down("md"));

  const data = [
    {
      image: "/images/icons/bfg.png",
    },
    {
      image: "/images/icons/griffith.png",
    },
    {
      image: "/images/icons/little_asthetic.png",
    },
    {
      image: "/images/icons/mystylee.png",
    },
    {
      image: "/images/icons/bonzun.png",
    },
    {
      image: "/images/icons/naidoc.jpg",
    },
    {
      image: "/images/icons/tafe.jpg",
    },
  ];

  return (
    <Box
      id="features"
      sx={{
        pt: {
          xs: 6,
          md: 8,
        },
        pb: {
          xs: 6,
          md: 4,
        },
        backgroundColor: "background.paper",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: 22, md: 38, lg: 44 },
            lineHeight: 1,
            fontWeight: { md: "bold" },
          }}
        >
          Australian Made Apps
        </Typography>
      </Grid>
      <Grid
        mt={3}
        md={3}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          justifySelf: "center",
          direction: "column",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: 40, md: 90, lg: 108 },
            lineHeight: 1,
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Achieve Global Success
        </Typography>
      </Grid>
      <Marquee
        gradient={false}
        speed={160}
        pauseOnHover={true}
        pauseOnClick={true}
        delay={0}
        play={true}
        autoFill
        direction="left"
      >
        {data.map(({ image }, index) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={image}
              width={matchMobileView ? 130 : 130}
              alt="Everest apps img"
              style={{ padding: 20 }}
            />
          </div>
          // <Box key={String(index)} sx={{ lineHeight: 0 }}>
          //   <img
          //     src={image}
          //     width={matchMobileView ? 130 : 130}
          //     alt="Everest apps img"
          //     style={{ padding: 20 }}
          //   />
          // </Box>
        ))}
      </Marquee>
    </Box>
  );
}

export default HomeGlobalSuccess;
