import "./App.css";
import MainLayout from "./components/layout/main-layout";
import HomeMain from "./components/home/home-main";
import HomeGlobalSuccess from "./components/home/global-success";
import HomeAppSuccess from "./components/home/app-success";
import HomeSuccessStrategy from "./components/home/success-strategy";
import HomePortfolio from "./components/home/portfolio";
import HomeContact from "./components/home/contact";
import HomePortfolioDev from "./components/home/development";
import { ThemeProvider, createTheme } from "@mui/material";
import PrivacyPolicyLayout from "./components/layout/privacy_policy";

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: [
        "montserrat",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    palette: {
      primary: {
        light: "#1057A5",
        main: "#002962",
        dark: "#4A86C5",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
    },
  });

  const nextPageWithLayout = () => {
    return (
      <>
        {/* <PrivacyPolicyLayout /> */}
        <HomeMain />
        <HomeGlobalSuccess />
        <HomeAppSuccess />
        <HomeSuccessStrategy />
        <HomePortfolio />
        <HomePortfolioDev />
        <HomeContact />
      </>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <MainLayout>{nextPageWithLayout()}</MainLayout>
    </ThemeProvider>
  );
}

export default App;
