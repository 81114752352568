import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { CardMedia, useMediaQuery, useTheme } from "@mui/material";

const exps = [
  {
    label: "Users",
    value: "2,000,000+",
  },
  {
    label: "Payments Processed",
    value: "1,000,000+",
  },
  {
    label: "Happy Clients",
    value: "100+",
  },
];

// const exps = [
//   {
//     label: "Apps Developed",
//     value: "3+",
//   },
//   {
//     label: "Website Designed",
//     value: "4+",
//   },
//   {
//     label: "Client Support",
//     value: "25+",
//   },
//   {
//     label: "Happy Clients",
//     value: "10+",
//   },
// ];

const ExpItem = ({ item }) => {
  const { value, label } = item;
  return (
    <Box sx={{ py: 0, textAlign: "center", mb: { xs: 1, md: 0 } }}>
      <Typography
        sx={{
          color: "primary.main",
          mb: { xs: 1, md: 1 },
          fontSize: { xs: 20, md: 30 },
          fontWeight: "bold",
        }}
      >
        {value}
      </Typography>
      <Typography
        color="text.secondary"
        variant="h5"
        sx={{
          fontSize: { xs: 20, md: 35 },
          fontWeight: "bold",
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

function HomeMain() {
  const { breakpoints } = useTheme();
  const matchMobileView = useMediaQuery(breakpoints.down("md"));

  return (
    <>
      <Box
        id="home"
        sx={{
          backgroundColor: "background.paper",
          top: 0,
          pt: 6,
          pb: { xs: 8, md: 10 },
          zIndex: -1,
        }}
      >
        <Container>
          <Grid
            container
            spacing={0}
            sx={{ flexDirection: "column", flexWrap: "inherit" }}
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  textAlign: "center",
                  height: "auto",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box>
                  <Typography
                    component="h2"
                    sx={{
                      position: "relative",
                      fontSize: { xs: 40, md: 100, lg: 150 },
                      letterSpacing: 1.5,
                      fontWeight: "bold",
                      lineHeight: 1.3,
                    }}
                  >
                    Bring It To Life.
                  </Typography>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: { xs: 19, md: 38, lg: 44 },
                      lineHeight: 1.6,
                    }}
                  >
                    Design, Develop, and Grow with Everest Apps.
                  </Typography>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    sx={{
                      color: "text.secondary",
                      fontSize: { xs: 14, md: 25, lg: 25 },
                      lineHeight: 1.6,
                      marginTop: 1,
                    }}
                  >
                    Welcome to Everest Apps, an Australian company at the
                    forefront of technology, design, and development. Our expert
                    consultants elevate your app to new heights, ensuring global
                    exposure and success.
                  </Typography>
                </Box>
                {/*  <Box sx={{ '& button:first-child': { mr: 2 } }}>
                <Button variant="outlined">Contact Us</Button>
                <Button variant="contained">Get a Free Quote</Button>
              </Box> */}
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ position: "relative" }}>
              <Box
                sx={{
                  lineHeight: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {!matchMobileView ? (
                  <CardMedia
                    component="video"
                    src="/video/animation_screens.mp4"
                    controls={false}
                    autoPlay
                    muted
                    loop
                  />
                ) : (
                  <CardMedia component="img" image="/images/apps.png" />
                )}
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{
              py: { xs: 4, md: 4 },
              px: { xs: 3, md: 6 },
              borderRadius: 4,
            }}
            style={{
              "box-shadow": "rgba(0, 0, 0, 0.1) 0px 4px 12px",
            }}
          >
            <Grid
              container
              spacing={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                justifySelf: "center",
                direction: "column",
              }}
            >
              {exps.map((item) => (
                <Grid key={item.value} item xs={8} md={4}>
                  <ExpItem item={item} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default HomeMain;
