import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { CardMedia, useMediaQuery, useTheme } from "@mui/material";

function HomePortfolioDev() {
  const { breakpoints } = useTheme();
  const matchMobileView = useMediaQuery(breakpoints.down("md"));
  return (
    <Box sx={{ backgroundColor: "background.paper" }}>
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={11} md={7}>
              <Typography
                component="h2"
                sx={{
                  position: "relative",
                  fontSize: { xs: 36, md: 46 },
                  mt: { xs: 4, md: 8 },
                  fontWeight: "bold",
                  lineHeight: 1,
                }}
              >
                Everest Apps Summit Sprint
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={11} md={7}>
              <Typography
                component="h2"
                sx={{
                  position: "relative",
                  fontSize: { xs: 18, md: 28 },
                  mt: { xs: 2, md: 4 },
                  lineHeight: { xs: 1.5, md: 2 },
                }}
              >
                Experience rapid development and accelerated delivery of
                innovative app solutions.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                lineHeight: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {matchMobileView ? (
                <CardMedia
                  component="img"
                  image="/images/mobile-app-development.png"
                />
              ) : (
                <CardMedia
                  sx={{ width: "50%" }}
                  component="video"
                  src="/video/animation_development.mp4"
                  controls={false}
                  autoPlay
                  muted
                  loop
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default HomePortfolioDev;
